<template>
  <div
    ref="trigger"
    class="pipeline-item"
    :class="{
      open: isOpen,
      'no-info': !item.pipeline_title && !item.pipeline_desc,
    }">
    <div
      class="program-stage"
      @click="open">
      <div class="caret"></div>
      <div class="program">
        <div class="program-content-wrapper">
          <div
            class="program-name font-larger"
            v-html="item.pipeline_program"></div>
          <div
            v-if="item.pipeline_target"
            class="program-target"
            v-html="item.pipeline_target"></div>
          <div
            class="program-content-stage font-bold"
            v-html="item.pipeline_stage"></div>
        </div>
        <div class="program-data-mobile">
          <template v-if="type === 'clinical'">
            <span class="prog-label">{{ item.pipeline_stage_clinical }}</span>
          </template>
          <template v-else>
            <span class="prog-label">{{
              item.pipeline_stage_preclinical
            }}</span>
          </template>
        </div>
      </div>
      <div class="stages">
        <div class="stage-progress-container">
          <div
            ref="progress"
            class="stage-progress"
            :class="type"></div>
        </div>
      </div>
    </div>
    <Vue3SlideUpDown v-model="isOpen">
      <div class="program-info">
        <div class="program-info-container">
          <div
            class="pipeline-title h3"
            v-html="item.pipeline_title"></div>
          <div class="program-info-label">Summary</div>
          <div
            class="pipeline-desc"
            v-html="item.pipeline_desc"></div>
        </div>
      </div>
    </Vue3SlideUpDown>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { Vue3SlideUpDown } from 'vue3-slide-up-down'
import { gsap } from 'gsap'

const props = defineProps(['item', 'type'])

const trigger = ref(null)
const progress = ref(null)
const isOpen = ref(false)

let tl

const open = () => {
  isOpen.value = !isOpen.value
}

const destroyTl = () => {
  tl.kill(true)
}

onMounted(() => {
  tl = gsap.timeline({
    onComplete: destroyTl,
    scrollTrigger: {
      trigger: trigger.value,
      start: 'top+=200 bottom',
    },
  })

  tl.to(progress.value, {
    width: `${props.item.pipeline_stage_progress}%`,
    duration: 0.5,
  })
})

onBeforeUnmount(() => {
  if (tl) {
    tl.kill(true)
  }
})
</script>

<style lang="scss">
.pipeline-item {
  position: relative;
  @include transition;

  @include tablet-landscape(1) {
    border-bottom: 1px solid $light-gray;
  }

  &.open {
    .program-stage {
      .caret:after {
        background-image: url('@/assets/img/circle-button-up-white.png');
      }
    }
  }

  &.no-info {
    pointer-events: none;

    .program-stage .caret {
      display: none;
    }
  }

  .program-stage {
    cursor: pointer;
    position: relative;
    display: flex;

    @include tablet-landscape(1) {
      flex-direction: column;
      padding: 11px 10px 30px 20px;
      padding-left: 0;
    }

    .caret {
      position: absolute;
      top: 5px;
      bottom: 0;
      left: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      margin: auto;

      @include tablet-landscape(1) {
        width: 40px;
        height: 40px;
        top: 11px;
        bottom: initial;
        right: 10px;
        left: initial;
      }

      &:after {
        content: '';
        position: relative;
        display: block;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url('@/assets/img/circle-button-down.png');
        background-repeat: no-repeat;
        background-size: contain;
        transform: scale(1);
        @include transition;
      }
    }

    .program-data-mobile {
      display: none;

      @include tablet-landscape(1) {
        display: block;
        font-size: 1.6rem;
        margin-left: 20px;
      }
    }

    .program {
      display: flex;
      align-items: center;
      width: 26.5%;
      min-height: 60px;
      padding-left: 30px;

      @include tablet-landscape(1) {
        width: 100%;
        min-height: 0px;
        padding-left: 10px;
        padding-bottom: 20px;
      }

      .program-content-wrapper {
        margin-left: 18%;

        @include tablet-landscape(1) {
          margin-left: 0;
        }

        > * {
          text-decoration: none !important;
          appearance: none !important;
        }

        .program-name {
          line-height: 1;
        }

        .program-content-stage {
          display: none;

          @include tablet-landscape(1) {
            display: block;
          }
        }
      }
    }

    .stages {
      position: relative;
      display: flex;
      justify-content: space-between;
      flex: 1;

      .stage-progress-container {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 32px;
        transform: translateY(-50%);

        @include tablet-landscape(1) {
          background: $light-gray;
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;
        }
      }

      .stage-progress {
        width: 0%;
        height: 100%;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;

        &.oncology {
          background: linear-gradient(90.02deg, #64bdff 21.34%, #5340e2 65.03%);
        }

        &.immunology {
          background: linear-gradient(84.34deg, #fbff3e -4.42%, #fc9024 80.16%);
        }

        &.neuroscience {
          background: linear-gradient(90deg, #ef3a62 0%, #872b6d 100%);
        }
      }
    }
  }

  .program-info {
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: calc(5% + 20px);
    padding-right: calc(5% + 20px);
    border-bottom: 1px solid $light-gray;
    background-color: $white;

    @include tablet-landscape {
      padding: 50px;
    }

    @include tablet-landscape(1) {
      padding: 20px;
    }

    .program-info-label {
      margin: 0 0 8px;
      letter-spacing: 1.44px;
      text-transform: uppercase;
      @include font-main-med;
    }

    .pipeline-title {
      margin: 0 0 24px;
    }
  }
}
</style>
